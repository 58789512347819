<template>
  <VContainer
    fluid
    pa-0
    ma-0
    class="addNewChart"
  >
    <VStepper v-model="stepLocal">
      <VStepperItems>
        <VStepperContent step="1">
          <VLayout column>
            <VFlex style="margin-top: 10px;">
              <VTabs background-color="transparent">
                <VTab active-class="active-tab">
                  All
                </VTab>
                <VTab
                  active-class="active-tab"
                  v-for="(category, index) in availableCategories"
                  :key="'category' + index"
                >
                  {{ category }}
                </VTab>
                <VTab
                  v-if="env.isDev"
                  style="position: absolute; right: 10px"
                  @click.prevent
                >
                  <VChip small>
                    DEBUG: Active Chart: {{ loadedChart.type || "Not Set" }}
                  </VChip>
                </VTab>
                <VTabItem
                  class="pl-4 pr-2"
                  style="background-color: #ebebeb;"
                >
                  <SelectChartType
                    :charts="getChartsByCategory(null)"
                    :active-chart="loadedChart"
                    @select:chart="chartType => selectChart(chartType, getChartComponentSettingsName(chartType.name))"
                  />
                </VTabItem>
                <VTabItem
                  class="pl-4 pr-2"
                  style="background-color: #ebebeb;"
                  v-for="(category, index) in availableCategories"
                  :key="'tabitem' + index"
                >
                  <SelectChartType
                    :charts="getChartsByCategory(category)"
                    :active-chart="loadedChart"
                    @select:chart="chartType => selectChart(chartType, getChartComponentSettingsName(chartType.name))"
                  />
                </VTabItem>
              </VTabs>
            </VFlex>
          </VLayout>
        </VStepperContent>

        <VStepperContent step="2">
          <ConfigureChartSelection
            v-if="showConfigChart"
            ref="report"
            :key="isChartConfigureLoaded"
            :id="{
              report_dashboard_id: dashboardId || null,
              id: report.id || reportId || null,
            }"
            :chart-type="chartType"
            :chart="report.data"
            :active-chart="loadedChart"
            @isFormReady="val => $emit('toggleSaveBtn', val)"
            @loading="val => $emit('toggleFormLoading', val)"
            @saved="val => $emit('saved', val)"
            @set:report="report => reportId = report.id"
          />
          <VAlert v-else>
            Please select a chart type!
          </VAlert>
        </VStepperContent>
      </VStepperItems>
    </VStepper>
  </VContainer>
</template>

<script>
import FormMixin from "@/mixins/Form";
import SupportsCharts from "@/mixins/SupportsCharts";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import SelectChartType from "./SelectChartType";
import ConfigureChartSelection from "./ConfigureChartSelection";

export default {
  name: "ChartSave",
  components: { SelectChartType, ConfigureChartSelection },
  mixins: [FormMixin, SupportsCharts],
  props: {
    step: {
      type: Number,
      required: true,
    },
    dashboardId: {
      type: Number,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  model: {
    prop: "step",
    event: "showNextBtn",
  },
  data() {
    return {
      chartType: null,
      reportId: null,
      canGetFormConfig: true,
      selectedChart: {
        type: null,
        type_id: null,
        settings: null,
        selected: false,
      },
      options: {
        available_stats: [],
      },
      defaultForm: {
        team_id: null,
        title: null,
        user_id: null,
        is_active: false,
        visible_to: null,
        type: "chart",
        category: null,
        chart_type: null,
        chart_type_id: null,
        config: {},
        is_pinned_to_main_dashboard: false,
        is_available_to_team: false,
        deleted_at: null,
      },
      editChartSettings: {},
      isContentLoading: false,
      isChartConfigureLoaded: 'configChart'
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  watch: {
    isFormReady: function (newVal, oldVal) {
      this.$emit("toggleSaveBtn", newVal);
    },
    step: function (newVal, oldVal) {
      if (oldVal !== newVal && newVal === 1 && this.chartType && this.chartType.name) {
        this.selectChart(this.chartType, this.getChartComponentSettingsName(this.chartType.name))
      }
    }
  },
  computed: {
    showConfigChart() {
      let value = (this.loadedChart.selected || this.report.id > 0);
      console.log('Show Chart', value);
      return value
    },
    loadedChart () {
      return this.selectedChart;
    },
    showChartConfig () {
      return this.isChartConfigureLoaded
    },
    availableCategories() {
      return Object.keys(this.getCategories);
    },
    chartComponent() {
      return this.selectedChart.type ? this.getChartComponentName(this.selectedChart.type) : null;
    },
    chartComponentSettings() {
      return this.selectedChart.type ? this.getChartComponentSettingsName(this.selectedChart.type) : null;
    },
    stepLocal: {
      get: function () {
        return this.step;
      },
      set: function () {
        this.$emit("showNextBtn");
      },
    },
    availableStats() {
      const availableStats = [];
      Object.keys(this.options.available_stats).reduce(
        (availableStats, group) => {
          availableStats.push({
            header: group,
          });
          availableStats.push(
            ...Object.values(this.options.available_stats[group])
          );
          return availableStats;
        },
        availableStats
      );
      return availableStats;
    },
  },
  methods: {
    ...mapActions({
      doSaveReport: "reporting/save",
      doGetFormConfig: "reporting/getFormConfig",
      doSaveDashboardItem: "reportDashboardItem/save",
    }),
    selectChart(chartType, settingsName = null, triggerNextButton = true) {
      const oldChartTypeName = this.chartType?.name;
      this.$log.debug('Selecting chart', chartType);
      this.form.chart_type = chartType.name;
      this.form.chart_type_id = chartType.id;
      this.chartType = chartType;
      this.selectedChart = {
        type: chartType.name,
        type_id: chartType.id,
        settings: settingsName || this.getChartComponentSettingsName(chartType.name),
        selected: true,
      };
      if (chartType.name !== oldChartTypeName) {
        this.isChartConfigureLoaded += '1';
      }
      if(triggerNextButton) {
        this.$emit("showNextBtn");
      }
      // this.loadFormConfig();
    },
    onSetSettings(settings) {
      this.form.config = { ...settings, chartRef: this.selectedChart.settings };
      this.form.user_id = this.$user.id;
      this.form.team_id = this.$user.team.id;
    },
    onReRenderChart(updatedSettings) {
      console.log("Ready for re-render", updatedSettings);
      // TO DO: Rerender the chart
    },
    onSave() {
      if (this.selectedChart.settings) {
        this.$refs[
          this.normalizeChartRef(this.selectedChart.settings)
        ].chartSetting();
      } else {
        let settings =
          this.selectedChart.type == "List of Users" ? { cols: 4 } : {};
        this.onSetSettings(settings);
      }
      return this.saveFormModel(this.doSaveReport)
        .then((result) => {
          if (this.report.exists) {
            // this.$emit("toggleChartDialog", true);
          } else {
            this.linkChartToDashboard(result.id);
          }
          this.$emit("saved", result);
        })
        .catch((error) => {
          this.$log.debug("Save new report error!");
        });
    },
    linkChartToDashboard(chartId) {
      let params = {
        team_id: this.$user.team.id,
        user_id: this.$user.id,
        report_id: chartId,
        report_dashboard_id: this.dashboardId,
      };
      return this.doSaveDashboardItem(params)
        .then((result) => {
          this.$bus.$emit("notificationMessage", "Report saved successfully!");
          // this.$emit("toggleChartDialog", true);
        })
        .catch((error) => {
          this.$log.debug("Link report dashboard error!");
        });
    },
    // onGetFormConfig() {
    //   return this.doGetFormConfig(this.resolvedActiveChart.type);
    // },
  },
  mounted() {
    if (this.report.exists) {
      this.isContentLoading = true;
      this.$emit("toggleSaveBtn", false);
    }
  },
  created() {
    this.selectChart(this.report.data.chart_type, this.getChartComponentSettingsName(this.report.data.chart_type.name), false);
  }
};
</script>
<style lang="scss">
.addNewChart {
  .chartAreaWrapper {
    width: 100%;
    overflow: hidden;
    > .row {
      margin: 0px 12px;
    }
  }
  .v-tab {
    padding: 0px;
  }
  .active-tab {
    background-color: #ebebeb;
    border: 1px solid #ebebeb;
  }
  .v-tabs-bar {
    padding: 0 !important;
  }
  .v-tabs-items {
    top: -15px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 435px;
    border: 1px solid #ebebeb;
  }
  .v-tabs-bar__content {
    background-color: white !important;
    padding: 0px !important;
  }
  .v-card__title {
    flex: 0 !important;
  }
  .center-label {
    font-size: 10px;
    width: 60px;
    top: 72px;
    position: absolute;
    left: 60px;
    line-height: 15px;
  }
  .donut-label {
    font-size: 10px;
    width: 60px;
    top: 190px;
    position: absolute;
    left: 22%;
    line-height: 15px;
  }
  .active-card {
    border: 2px solid #7479db;
  }
  .v-stepper__wrapper {
    height: 100%;
  }
  .v-stepper__content {
    padding: 0px;
    height: 100%;
  }
  .v-text-field__details {
    display: none;
  }
  .v-slide-group__content .v-tabs-bar__content {
    padding: 0px;
  }
  .v-tab--active {
    border-radius: 10px;
  }
  .v-stepper {
    box-shadow: none;
  }
  .v-select__selection--comma {
    font-weight: 300 !important;
  }
  .chart-type > .v-input__control > .v-input__slot {
    border-bottom: 1px solid rgb(218, 218, 218) !important;
    border-radius: unset !important;
  }
  .card-actions {
    height: 40px;
  }
  .chart-title {
    font-size: 11px;
  }
  .settings-area {
    border-left: 1px solid rgb(218, 218, 218) !important;
    background-color: #f2f2f2 !important;
    min-height: 400px;
  }
  .chart-type-header {
    border-right: 1px solid #dadada;
  }
}
</style>
