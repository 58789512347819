<template>
  <div class="no-data-chart">
    <h2 v-if="Object.keys(chart).length === 0 || chart.reporting_data.length === 0">
      No data generated for this interval.
    </h2>
    <SummaryComponent
      :chart="chart"
      v-else
    />
    <br>
  </div>
</template>
<script>
import SummaryComponent from './Summary/index.vue'

export default {
  components: {SummaryComponent},
  props: {
    chart: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.no-data-chart {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
