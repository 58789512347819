<template>
  <VContainer
      class="configure-filters-section"
      style="background-color: #ececec"
  >
    <VSkeletonLoader
        min-height="500px"
        style="min-height: 555px"
        type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"
        :loading="isContentLoading"
    >
      <VRow
          v-for="(filter, key) in filters"
          :key="key"
      >
        <VCol cols="12 py-0 chart-type-header">
          <VSubheader
              class="my-auto font-bold pa-0"
          >
            <strong>{{ filter.name }}</strong>
          </VSubheader>
        </VCol>
        <VCol
            grow
            class="d-flex"
        >
          <VAutocomplete
              :value="selectedFilters[filter.id]"
              multiple
              no-data-text="All"
              solo
              class="chart-type"
              label="All"
              ref="autocompletes"
              :items="options[filter.id]"
              item-text="title"
              item-value="id"
              @change="filterChanged($event, filter, key)"
          />
        </VCol>
      </VRow>
    </VSkeletonLoader>
  </VContainer>
</template>
<script>
import ActivityMixin from "@/mixins/Activity";
import { mapActions } from 'vuex'

export default {
  name: "ConfigureChartFilters",
  mixins: [
    ActivityMixin
  ],
  props: {
    filters: {
      type: Array,
      required: true,
      default: () => {
        return {}
      }
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isContentLoading: true,
      options: {}
    }
  },
  computed: {
    selectedFilters: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    ...mapActions({
      doGetFilterOptions: 'reporting/getFilterOptions'
    }),
    /**
     *
     * @param {Int} filterIndex
     * @return {Boolean}
     */
    shouldLoadOptions(filterIndex) {
      console.log('Filter index', filterIndex)
      let selectedAutocomplete = this.$refs.autocompletes[filterIndex]
      selectedAutocomplete.loading = true
      selectedAutocomplete.disabled = true
      selectedAutocomplete.lable = 'Loading...'
      setTimeout(() => {
        selectedAutocomplete.items = ['Yes', 'No']
        selectedAutocomplete.disabled = false
        selectedAutocomplete.activateMenu();
      }, 2000)
      // If focus event and has results do not reload options
      if (this.selectedFilters.hasOwnProperty(filterIndex)
        && this.selectedFilters[filterIndex].length > 0
      ) {
        return false;
      }
    },

    filterChanged(value, filter, key) {
      console.log('Value', value);
      console.log('Value', filter);
      console.log('Value', key);
      this.selectedFilters[filter.id] = value
    }
  },
  mounted() {
    let $this = this
    Object.entries(this.filters).forEach(([filterId, filter]) => {
      console.log('Filters foreach', filter);
      this.doGetFilterOptions(filterId).then((result) => {
        $this.options[filterId] = result
        if (Object.keys($this.options).length === Object.keys($this.filters).length) {
          $this.isContentLoading = false
        }
        console.log('Loading results of filter', result);
      })
    });
  }
}
</script>
<style lang="scss" scoped>
.chart-type-header {
  border-right: none !important;
}
</style>
