<template>
  <VRow style="align-items: stretch">
    <VCol
      v-for="chartType in charts"
      class="px-2"
      :lg="chartType.widthLG ? chartType.widthLG : 3"
      :md="chartType.widthMD ? chartType.widthMD : 3"
      :sm="chartType.widthSM ? chartType.widthSM : 12"
      :key="chartType.name"
    >
      <VCard
        style="cursor: pointer"
        :class="activeChart.selected && activeChart.type == chartType.name ? 'active-card' : ''"
        @click.native="$emit('select:chart', chartType)"
      >
        <VCardText>
          <div class="chartAreaWrapper">
            <component
              v-if="isSupportedChart(chartType.name)"
              :is="getChartComponentName(chartType.name)"
              :ref="normalizeChartRef(chartType.name)"
            />
          </div>
        </VCardText>
        <VCardActions class="pt-0 justify-center card-actions">
          <strong class="chart-title">{{ chartType.name }}</strong>
        </VCardActions>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import FormMixin from "@/mixins/Form";
import SupportsCharts from "@/mixins/SupportsCharts";

export default {
  name: "SelectChartType",
  props: {
    charts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mixins: [SupportsCharts],
  mounted() {
    this.$log.debug('Loading charts', this.charts);
  }
};
</script>
<style lang="scss">
.addNewChart {
  .chartAreaWrapper {
    width: 100%;
    overflow: hidden;
    > .row {
      margin: 0px 12px;
    }
  }
}
</style>
